/* General App Styles */
.App {
  text-align: center; /* Center align text */
}

/* First Banner */
.banner1 {
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center the title horizontally */
  background-color: black; /* Black background */
  color: white; /* White text */
  padding: 10px 20px; /* Padding around the banner */
  position: relative; /* Allow positioning of children */
  width: 100%; /* Ensure it takes full width */
  box-sizing: border-box; /* Include padding in width calculation */
}

.hamburger {
  position: absolute; /* Position the hamburger icon */
  left: 20px; /* Position it on the left */
  cursor: pointer; /* Change cursor on hover */
}

.line {
  width: 30px; /* Width of the hamburger lines */
  height: 3px; /* Height of the hamburger lines */
  background-color: white; /* Color of the lines */
  margin: 5px; /* Spacing between lines */
  transition: all 0.3s; /* Smooth transition */
}

.open {
  transform: rotate(45deg); /* Rotate lines for animation */
}

.open:nth-child(2) {
  opacity: 0; /* Hide the middle line when open */
}

.open:nth-child(3) {
  transform: rotate(-45deg); /* Rotate the bottom line for animation */
}

/* Second Banner */
.banner2 {
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center the title horizontally */
  background-color: white; /* White background */
  color: black; /* Black text */
  padding: 10px; /* Padding around the banner */
  position: relative; /* Allow positioning of children */
  width: 100%; /* Ensure it takes full width */
  box-sizing: border-box; /* Include padding in width calculation */
}

/* Navbar */
.navbar {
  background-color: black; /* Navbar background */
  padding: 10px; /* Padding for navbar */
  position: absolute; /* Positioning */
  left: 20px; /* Align with hamburger */
  top: 60px; /* Space below the banner */
  display: flex; /* Flexbox for horizontal alignment */
  flex-direction: column; /* Stack links vertically */
  border: 1px solid white; /* White border */
}

.navbar a {
  color: white; /* White text for links */
  text-decoration: none; /* No underline */
  padding: 5px; /* Padding for links */
}

.navbar a:hover {
  text-decoration: underline; /* Underline on hover */
}

/* Content Area */
.content {
  margin-top: 5px; /* Space below the banner */
}

.filter-group {
  margin-bottom: 10px; /* Space between filters */
}

label {
  margin-right: 10px; /* Space between label and input */
  margin-left: 20px; /* Space between label and input */
  font-weight: bold; /* Make labels bold */
}

/* Table Styles */
table {
  max-width: 90%; /* Ensure table takes full width */
  margin: 0 auto; /* Center the table horizontally */
  border-collapse: collapse; /* Collapse borders */
  box-sizing: border-box; /* Include padding/border in width calculation */
}

table, th, td {
  border: 1px solid #ddd; /* Table border */
}

th, td {
  padding: 12px; /* Cell padding */
  text-align: left; /* Left align text */
}

th {
  background-color: #f1f1f1; /* Header background */
}

/* Add hover effect on table rows */
table tr:hover {
  background-color: #f9f9f9; /* Highlight color on hover */
}

/* === Media Queries for Responsive Design === */

/* For tablets and small laptops (up to 1024px) */
@media (max-width: 1024px) {
  .banner1, .banner2 {
    padding: 10px 0; /* Reduce horizontal padding if needed */
    font-size: 12px; /* Further reduce title size */
    margin: 0; /* Remove any default margins */
  }

  .navbar {
    left: 10px; /* Adjust position of navbar */
    top: 50px; /* Adjust navbar top position */
  }

  table {
    width: 90%; /* Adjust table width for smaller screens */
    font-size: 12px;
    box-sizing: border-box;
  }
}

/* For very small mobile screens (up to 480px) */
@media (max-width: 768px) {
  .banner1, .banner2 {
    padding: 5px 0; /* Further reduce padding */
    font-size: 12px; /* Further reduce title size */
    margin: 0; /* Remove any default margins */
  }

  .hamburger {
    left: 5px; /* Further adjust hamburger position */
  }

  .navbar {
    left: 5px; /* Further adjust navbar position */
    top: 40px; /* Reduce space below the banner */
  }

  table {
    width: 90%; /* Keep table full width */
    font-size: 12px;
    box-sizing: border-box;
  }

  th, td {
    padding: 8px; /* Adjust padding for small screens */
  }
}
