.commercial-banner {
  display: flex;
  align-items: center;
  justify-content: center; /* Center logo and text */
  padding: 20px 20px 30px; /* Added extra padding on the bottom */
  border-radius: 8px;
  color: black; /* Text is always black */
  max-width: 100%;
  text-align: center;
}

.logo {
  height: 80px;
  margin-right: 15px;
}

.banner-content {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center text in column */
}

.banner-content p {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  color: black; /* Ensure text is always black */
}

.banner-content a {
  color: black; /*  link color to black */
  font-weight: bold;
  text-decoration: none;
  margin-top: 5px;
}

.banner-content a:hover {
  text-decoration: underline;
}
